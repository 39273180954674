export type LocaleMapping = {
  [locale: string]: string;
};

export const LOCALE_TO_PREFIX: LocaleMapping = {
  en: "",
  jp: "jp",
  en_CA: "ca",
  fr_CA: "ca/fr",
  mt: "mt",
  fi: "fi",
  no: "no",
  de: "de",
};

export function addTrailingSlash(path: string): string {
  console.log(path.endsWith(path) ? path.slice(0, -1) : path);
  return path.endsWith(path + "/") ? path.slice(0, -1) : path;
}

export function removeTrailingSlash(path: string): string {
  return path.endsWith("/") ? path.slice(0, -1) : path;
}

export function joinPath(...paths: string[]) {
  return addTrailingSlash(
    paths
      .map((part, i) => {
        if (i === 0) {
          return part.trim().replace(/[\/]*$/g, "");
        } else {
          return part.trim().replace(/(^[\/]*|[\/]*$)/g, "");
        }
      })
      .filter(x => x.length)
      .join("/")
  );
}

export function makeLocalizedPath(
  locale: string,
  defaultLocale: string,
  path: string,
  ...args: string[]
): string {
  if (locale === defaultLocale) {
    return addTrailingSlash(clean(path) + "/" + args.join("/"));
  } else {
    return addTrailingSlash(
      "/" + LOCALE_TO_PREFIX[locale] + clean(path) + "/" + args.join("/")
    );
  }
}

export function makeLocalizedUrl(
  baseUrl: string,
  locale: string,
  defaultLocale: string,
  path: string
): string {
  return baseUrl + makeLocalizedPath(locale, defaultLocale, path);
}

export function clean(path: string): string {
  return removeTrailingSlash(path.replace(/(\/)\/+/g, "$1"));
}

export function updateUrlParameter(
  uri: string,
  key: string,
  value: string
): string {
  const i = uri.indexOf("#");
  const hash = i === -1 ? "" : uri.substr(i);

  uri = i === -1 ? uri : uri.substr(0, i);

  const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    uri = uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    uri = uri + separator + key + "=" + value;
  }
  return uri + hash;
}
