import { useContext, createContext } from 'react';

type TranslationConfig = {
  n: [
    {
      t: string;
      d: string;
    }
  ];
};
type CurrenciesConfig = {
  n: [
    {
      v: string;
      s: string;
    }
  ];
};

export type ConfigContextData = {
  translationConfig: {
    translations: TranslationConfig[];
  };
  currenciesConfig: {
    currencies: CurrenciesConfig[];
  };

  CASINO: { path: string };
  LIVE_CASINO: { path: string };
  DEPOSIT: { path: string };
  SIGN_UP: { path: string };
  QUICK_DEPOSIT_SIGN_IN: { path: string };
  ENTER_CODE: { path: string };
  LOBBY: { path: string };
  SIGN_IN: { path: string };
  PROFILE: { path: string };
  WITHDRAWAL: { path: string };
  HOME: { path: string };
};

export type ConfigContext = {
  translations: {
    [translation: string]: string;
  };
  getTranslation: any;

  currencies: {
    [currencies: string]: string;
  };
  getCurrencySymbol: any;

  CASINO: { path: string };
  LIVE_CASINO: { path: string };
  DEPOSIT: { path: string };
  SIGN_UP: { path: string };
  QUICK_DEPOSIT_SIGN_IN: { path: string };
  ENTER_CODE: { path: string };
  LOBBY: { path: string };
  SIGN_IN: { path: string };
  PROFILE: { path: string };
  WITHDRAWAL: { path: string };
  HOME: { path: string };
};

const defaultConfig: ConfigContext = {
  translations: {},
  currencies: {},
  getTranslation: () => '',
  getCurrencySymbol: () => '',

  CASINO: { path: '' },
  LIVE_CASINO: { path: '' },
  DEPOSIT: { path: '' },
  SIGN_UP: { path: '' },
  QUICK_DEPOSIT_SIGN_IN: { path: '' },
  ENTER_CODE: { path: '' },
  LOBBY: { path: '' },
  SIGN_IN: { path: '' },
  PROFILE: { path: '' },
  WITHDRAWAL: { path: '' },
  HOME: { path: '' },
};

export const ConfigContext = createContext<ConfigContext>(defaultConfig);

export function dataToConfig(
  data: ConfigContextData
): ConfigContext {
  if (!data.translationConfig) {
    console.error('No config data recieved');
    return defaultConfig;
  }
  const groupedCurrencies = data.currenciesConfig.currencies;

  const { translationConfig, ...paths } = data;
  const groupedTranslations = translationConfig.translations;

  const translations = groupedTranslations.map(translationsNode => {
    const translation = translationsNode.n.find(
      translationNode => typeof translationNode.d === 'string'
    );
    return translation ? { [translation.t]: translation.d } : {};
  });
  const currencies = groupedCurrencies.map(currenciesNode => {
    const currency = currenciesNode.n.find(
      currenciesNode => typeof currenciesNode.v === 'string'
    );
    return currency ? { [currency.v]: currency.s } : {};
  });

  const currenciesObject = Object.assign({}, ...currencies);
  const translationsObject = Object.assign({}, ...translations);

  return {
    ...paths,
    translations: translationsObject,
    currencies: currenciesObject,
    getTranslation: (key: string) =>
      translationsObject[key] || `No translation for key: ${key}`,
    getCurrencySymbol: (key: string) => currenciesObject[key] || null,
  };
}

export const useConfigContext = () => useContext(ConfigContext);
