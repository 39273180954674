import React, { FC, ReactNode } from 'react';
import { useIsAuthenticated } from '@dinotechltd/core';

export type ContentBlockProps = {
  content: { type: string; children: Array<Object> };
};
export type ImageHeadingBlockProps = {
  maxWidth: string;
  image: any;
};
export type LoginFormProps = {
  title: string;
  subtitle: string;
  phoneNumberLabel: string;
  sendCodeButton: string;
};
export type EnterCodeFormProps = {
  title: string;
  subtitle: string;
  phoneNumberLabel: string;
  enterCodeButton: string;
  enterCodeLabel: string;
  resendText: string;
  resendPathText: string;
};
export type AdditionalInformationFormProps = {
  dobFieldLabel: string;
  emailFieldLabel: string;
  firstNameFieldLabel: string;
  lastNameFieldLabel: string;
  streetFieldLabel: string;
  cityFieldLabel: string;
  countryFieldLabel: string;
  zipCodeFieldLabel: string;
  dayDepositLimitLabel: string;
  weekDepositLimitLabel: string;
  monthDepositLimitLabel: string;
  showLimits: boolean;
  submitButtonName: string;
};
export type CashierProps = {
  amount1: string;
  amount2: string;
  amount3: string;
  isWithdrawal: boolean;
  title: string;
};
export type Menu = { contentId: string };
export type MenuProps = { children: ReactNode; menu: Menu };
export type PageLinkProps = {
  path: string;
  title: string;
  menuTitle: string;
  requiresAuthentication: boolean;
  menu: Menu;
  linkType?: string;
  text?: string;
};
export type GameListProps = {
  name: string;
  slug: string;
  enabled: boolean;
  autofill: boolean;
  games: any;
};
export type UpdateEmailFormProps = {
  title: string;
  emailFieldLabel: string;
  submitButtonName: string;
};
export type UpdatePersonalDetailsFormProps = {
  title: string;
  firstNameFieldLabel: string;
  lastNameFieldLabel: string;
  dobFieldLabel: any;
  submitButtonName: string;
};
export type UpdateAddressFormProps = {
  title: string;
  streetFieldLabel: string;
  cityFieldLabel: string;
  countryFieldLabel: string;
  zipCodeFieldLabel: string;
  submitButtonName: string;
};
export type UpdateMarketingSettingsFormProps = {
  title: string;
  acceptsEmailMarketingFieldLabel: string;
  acceptsSmsMarketingFieldLabel: string;
  submitButtonName: string;
};
export type PaymentHistoryBlockProps = {
  showDeposits: boolean;
  showWithdrawals: boolean;
};
export type LockAccountFormProps = {
  title: string;
  subtitle: string;
  customButtonName: string;
  submitButtonName: string;
};
export type UpdateDepositLimitFormProps = {
  title: string;
  subtitle: string;
  submitButtonName: string;
};
export type SeoBoxBlockProps = {
  body: any;
};
export type CustomContentBoxBlockProps = {
  backgroundColor?: string;
  padding?: string;
  margin?: string;
  maxWidth?: string;
  body: any;
};
export type UpdateSessionTimeLimitFormProps = {
  title: string;
  subtitle: string;
  submitButtonName?: string;
  hideButton?: boolean;
  formSubmit?: any;
};

export type GameSearchTextFieldProps = { title: string };

export type AccessCashierProps = {
  isWithdrawal: string;
  amount1?: number;
  amount2?: number;
  amount3?: number;
  title: string;
  customLabel: string;
  amountLabel: string;
  amountPlaceholder: string;
  buttonLabel: string;
  size?: string;
};

export type ImageSliderProps = {
  body: any;
};

export type SimpleImageSliderProps = {
  body: any;
};

export type CardProds = {
  title: string;
  description: string;
  type: string;
};

export type CardListProps = {
  title: string;
  description: string;
  cards: any;
};
export type TextSectionProps = {
  title: string;
  description: string;
  type: string;
};
export type ImageSectionProps = {
  title: string;
  description: string;
  type: string;
};
export type Error404Props = {
  title: string;
  description: string;
  buttonText: string;
  buttonPath: string;
  body: any;
};

export type PaymentMethodsProps = {
  title: string;
  body: any;
};

export type DocumentUploadProps = {
  documentUploadMessage: string;
  documentUploadSuccessMessage: string;
  documentUploadErrorMessage: string;
};

export type RichTextRendererArgs = {
  renderContentBlock?(props: ContentBlockProps): ReactNode;
  renderImageHeadingBlock?(props: ImageHeadingBlockProps): ReactNode;
  renderLoginFormBlock?(props: LoginFormProps): ReactNode;
  renderSeoBoxBlock?(props: SeoBoxBlockProps): ReactNode;
  renderCustomContentBoxBlock?(props: CustomContentBoxBlockProps): ReactNode;
  renderEnterCodeBlock?(props: EnterCodeFormProps): ReactNode;
  renderAdditionalInformationBlock?(
    props: AdditionalInformationFormProps
  ): ReactNode;
  renderMenuBlock?(props: MenuProps): ReactNode;
  renderPageLinkBlock?(props: PageLinkProps): ReactNode;
  renderCookieDeclarationBlock?(): ReactNode;
  renderCashierBlock?(props: CashierProps): ReactNode;
  renderGameSearchTextFieldBlock?(props: GameSearchTextFieldProps): ReactNode;
  renderGameListBlock?(props: GameListProps): ReactNode;
  renderGameSearchBoxBlock?(props: GameListProps): ReactNode;
  renderWalletBlock?(): ReactNode;
  renderUpdateEmailFormBlock?(props: UpdateEmailFormProps): ReactNode;
  renderUpdatePersonalDetailsFormBlock?(
    props: UpdatePersonalDetailsFormProps
  ): ReactNode;
  renderUpdateAddressFormBlock?(props: UpdateAddressFormProps): ReactNode;
  renderUpdateMarketingSettingsFormBlock?(
    props: UpdateMarketingSettingsFormProps
  ): ReactNode;
  renderGameRoundsBlock?(): ReactNode;
  renderSessionHistoryBlock?(): ReactNode;
  renderPaymentHistoryBlock?(props: PaymentHistoryBlockProps): ReactNode;
  renderLockAccountFormBlock?(props: LockAccountFormProps): ReactNode;
  renderUpdateDepositLimitFormBlock?(
    props: UpdateDepositLimitFormProps
  ): ReactNode;
  renderUpdateSessionTimeLimitFormBlock?(
    props: UpdateSessionTimeLimitFormProps
  ): ReactNode;
  renderActivitiesHistoryBlock?(): ReactNode;
  renderAccessCashierBlock?(props: AccessCashierProps): ReactNode;
  renderImageSliderBlock?(props: ImageSliderProps): ReactNode;
  renderSimpleImageSliderBlock?(props: SimpleImageSliderProps): ReactNode;
  renderCardBlock?(props: CardProds): ReactNode;
  renderCardListBlock?(props: CardListProps): ReactNode;
  renderTextSectionBlock?(props: TextSectionProps): ReactNode;
  renderImageSectionBlock?(props: ImageSectionProps): ReactNode;
  renderSafetyAndCertificatesBlock?(): ReactNode;
  renderError404Block?(props: Error404Props): ReactNode;
  renderPaymentMethodsBlock?(props: PaymentMethodsProps): ReactNode;
  renderDocumentUploadBlock?(props: DocumentUploadProps): ReactNode;
  renderVerifyEmailBlock?(): ReactNode;
  renderHeaderBannerBlock?(props: any): ReactNode;
  renderCashbackHistoryBlock?(): ReactNode;
  renderOther?(props: any): ReactNode | null;
};

export type RenderRichTextProps = {
  data: any;
  only?: string[];
  currentMenu?: Menu;
};

const HideIfNotLoggedIn: FC<{}> = ({ children }) => {
  const { isLoggedIn } = useIsAuthenticated();

  if (isLoggedIn) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export function richTextRenderer(args: RichTextRendererArgs) {
  const Renderer = ({ data, currentMenu, ...props }: RenderRichTextProps) => {
    const only = props.only || [];
    const children = props.only
      ? data.children.filter((node: any) => only.includes(node.__typename))
      : data.children;

    return children.map((node: any, index: number) => {
      function renderBlock(f: Function | undefined, ...args: any) {
        if (f) {
          return <React.Fragment key={index}>{f(...args)}</React.Fragment>;
        } else {
          return null;
        }
      }

      switch (node.__typename) {
        case 'DinoBlock':
          // TODO: We should not add an empty paragraph to the top of every document
          if (
            index == 0 &&
            node.type === 'paragraph' &&
            node.children.length === 1 &&
            node.children[0].text === ''
          ) {
            return null;
          }
          return renderBlock(args.renderContentBlock, { content: node });
        case 'LoginFormBlock':
          return renderBlock(args.renderLoginFormBlock, node.loginForm);
        case 'MenuBlock':
          const children = (
            <Renderer
              data={node.menu.body}
              currentMenu={node.menu}
              {...props}
              only={['PageLinkBlock']}
            />
          );
          return renderBlock(args.renderMenuBlock, {
            children,
            menu: node.menu,
          });
        case 'PageLinkBlock':
          if (!args.renderPageLinkBlock) {
            return null;
          }
          return node.page.requiresAuthentication ? (
            <HideIfNotLoggedIn key={index}>
              {args.renderPageLinkBlock({
                ...node.page,
                menu: currentMenu,
                linkType: node.linkType,
                text: node.text,
              })}
            </HideIfNotLoggedIn>
          ) : (
            <React.Fragment key={index}>
              {args.renderPageLinkBlock({
                ...node.page,
                menu: currentMenu,
                linkType: node.linkType,
                text: node.text,
              })}
            </React.Fragment>
          );
        case 'EnterCodeFormBlock':
          return renderBlock(args.renderEnterCodeBlock, node.enterCodeForm);
        case 'AdditionalInformationBlock':
          return renderBlock(
            args.renderAdditionalInformationBlock,
            node.additionalInformationForm
          );
        case 'CookieDeclarationBlock':
          return renderBlock(args.renderCookieDeclarationBlock);
        case 'CashierBlock':
          return renderBlock(args.renderCashierBlock, node);
        case 'GameSearchTextFieldBlock':
          return renderBlock(args.renderGameSearchTextFieldBlock, node);
        case 'GameListBlock':
          return renderBlock(args.renderGameListBlock, node.gameList);
        case 'GameSearchBoxBlock':
          return renderBlock(args.renderGameSearchBoxBlock, node);
        case 'WalletBlock':
          return renderBlock(args.renderWalletBlock);
        case 'UpdateEmailFormBlock':
          return renderBlock(
            args.renderUpdateEmailFormBlock,
            node.updateEmailForm
          );
        case 'UpdatePersonalDetailsFormBlock':
          return renderBlock(
            args.renderUpdatePersonalDetailsFormBlock,
            node.updatePersonalDetailsForm
          );
        case 'UpdateAddressFormBlock':
          return renderBlock(
            args.renderUpdateAddressFormBlock,
            node.updateAddressForm
          );
        case 'UpdateMarketingSettingsFormBlock':
          return renderBlock(
            args.renderUpdateMarketingSettingsFormBlock,
            node.updateMarketingSettingsForm
          );
        case 'GameRoundsBlock':
          return renderBlock(args.renderGameRoundsBlock);
        case 'PaymentHistoryBlock':
          return renderBlock(args.renderPaymentHistoryBlock, node);
        case 'DocumentUploadBlock':
          return renderBlock(args.renderDocumentUploadBlock, node);
        case 'SessionHistoryBlock':
          return renderBlock(args.renderSessionHistoryBlock, node);
        case 'LockAccountFormBlock':
          return renderBlock(
            args.renderLockAccountFormBlock,
            node.lockAccountForm
          );
        case 'UpdateDepositLimitFormBlock':
          return renderBlock(
            args.renderUpdateDepositLimitFormBlock,
            node.updateDepositLimitForm
          );
        case 'UpdateSessionTimeLimitFormBlock':
          return renderBlock(
            args.renderUpdateSessionTimeLimitFormBlock,
            node.updateSessionTimeLimitForm
          );
        case 'ActivitiesHistoryBlock':
          return renderBlock(args.renderActivitiesHistoryBlock);
        case 'AccessCashierBlock':
          return renderBlock(args.renderAccessCashierBlock, node.accessCashier);
        case 'SeoBoxBlock':
          return renderBlock(args.renderSeoBoxBlock, node.seoBox);
        case 'CustomContentBoxBlock':
          return renderBlock(
            args.renderCustomContentBoxBlock,
            node.customContentBox
          );
        case 'ImageSliderBlock':
          return renderBlock(args.renderImageSliderBlock, node.imageSlider);
        case 'SimpleImageSliderBlock':
          return renderBlock(
            args.renderSimpleImageSliderBlock,
            node.simpleImageSlider
          );
        case 'CardBlock':
          return renderBlock(args.renderCardBlock, node.card);
        case 'TextSectionBlock':
          return renderBlock(args.renderTextSectionBlock, node.textSection);
        case 'ImageSectionBlock':
          return renderBlock(args.renderImageSectionBlock, node.imageSection);
        case 'ImageHeadingBlock':
          return renderBlock(args.renderImageHeadingBlock, node);
        case 'CardListBlock':
          return renderBlock(args.renderCardListBlock, node.cardList);
        case 'SafetyAndCertificatesBlock':
          return renderBlock(args.renderSafetyAndCertificatesBlock);
        case 'Error404Block':
          return renderBlock(args.renderError404Block, node.error404);
        case 'PaymentMethodsBlock':
          return renderBlock(
            args.renderPaymentMethodsBlock,
            node.paymentMethods
          );
        case 'VerifyEmailBlock':
          return renderBlock(args.renderVerifyEmailBlock, node.verifyEmail);
        case 'HeaderBannerBlock':
          return renderBlock(args.renderHeaderBannerBlock, node.headerBanner);
        case 'CashbackHistoryBlock':
          return renderBlock(args.renderCashbackHistoryBlock, node);
        default:
          return renderBlock(args.renderOther, node.__typename, node);
      }
    });
  };

  return Renderer;
}
